import React from "react";
import Layout from "../Layout";
import Head from "../Head";
import HeroText from "../HeroText";
import LandingImage from "../LandingImage";
import EniosolMarquee from "../Marquee";
import Services25 from "../Services25";
import TestimonialSwiper25 from "../TestimonialSwiper25";
import Chart25 from "../Chart25";
import {Link} from "gatsby";
import Energierechner from "../Energierechner";
import Marquee from "react-fast-marquee";
import {GatsbyImage} from "gatsby-plugin-image";
import CTA25 from "../CTA25";
import LandingSection25 from "../LandingSection25";
import CustomSanityImage from "../helper/CustomSanityImage";
import MyPortableText24 from "../MyPortableText24";

const Landing25 = ({page, settings, oldpage}) => {

    console.log(page);

    return (
        <Layout landing={true} settings={settings} subfolder={true}>
                <Head title={page?.seo?.seo_title}/>
                {page?.pageBuilder?.map((section, index) => {
                    if(section._type === 'hero25'){
                        return <React.Fragment key={index}>
                            <LandingImage page={section}></LandingImage>
                        </React.Fragment>
                    } else if(section._type === 'intro25'){
                        return <React.Fragment key={index}>
                            <HeroText images={section.images}></HeroText>
                        </React.Fragment>
                    } else if(section._type === 'marquee25'){
                        return <React.Fragment key={index}>
                            <EniosolMarquee outerClass={`fixedHeight marqueeWrapper !overflow-y-hidden ${section?.color?.hex === '#000000' && 'bgblack'}`} innerClass={`${section?.color?.hex === '#000000' && 'color-white'} uppercase`} content={section?.headline}></EniosolMarquee>
                        </React.Fragment>
                    } else if(section._type === 'services25'){
                        return <React.Fragment key={index}>
                            <Services25 data={section}></Services25>
                        </React.Fragment>
                    } else if(section._type === 'info25'){
                        return <React.Fragment key={index}>
                            <LandingSection25 el={section}></LandingSection25>
                        </React.Fragment>
                    } else if(section._type === 'testimonials25'){
                        return <React.Fragment key={index}>
                            <TestimonialSwiper25 testimonials={oldpage.testimonials}></TestimonialSwiper25>
                        </React.Fragment>
                    } else if(section._type === 'fullimage25'){
                        return <React.Fragment key={index}>
                            <div className={"h-[50vh] overflow-hidden"}>
                                <CustomSanityImage imageData={section?.image}></CustomSanityImage>
                            </div>
                        </React.Fragment>
                    } else if(section._type === 'dienstleistungen25'){
                        return <React.Fragment key={index}>
                            <div className="bg-black px-base pt-doublebase tablet:pt-quadbase pb-doublebase tablet:pb-quadbase">
                                <p className="large text-white tablet:hidden mb-doublebase">{section.headline1} <span> </span> {section.headline2}</p>
                                <div className="hidden tablet:block laptop:w-[66%]">
                                    <div className={"flex mb-doublebase"}>
                                        <div className="w-1/2">
                                            <p className="large text-white max-w-[150px]">{section.headline1}</p>
                                        </div>
                                        <div className="w-1/2">
                                            <p className="large text-white max-w-[150px]">{section.headline2}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="laptop:flex">
                                    <div className="laptop:w-[66%] laptop:pr-base">
                                        {section?.items?.map((item, index) => {
                                            return <div className={"flex border-t-2 border-b-2 border-white mb-[3px] py-halfbase"}>
                                                <div className="w-1/2">
                                                    <p className="small text-white">{item?.title}</p>
                                                </div>
                                                <div className="w-1/2">
                                                    <p className="small text-white">{item?.text}</p>
                                                </div>
                                            </div>
                                        })}
                                        <div className={"tablet:flex text-white mt-doublebase"}>
                                            <div className="tablet:mr-halfbase tablet:w-1/2">
                                                <MyPortableText24 value={section?._rawText1}></MyPortableText24>
                                            </div>
                                            <div className="tablet:ml-halfbase tablet:w-1/2 mt-base tablet:mt-0">
                                                <MyPortableText24 value={section?._rawText2}></MyPortableText24>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"flex flex-col laptop:w-[33%] mt-base laptop:mt-0"}>
                                        {section?.buttons?.map((button, index) => {
                                            return <Link to={button?.link} className="mb-[5px] btn !p-[5px] flex items-center smalltext bold filled uppercase !w-full max-w-[400px]" style={{background: button?.color?.hex}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className={"!h-[45px] !w-auto"} width="53" height="53" viewBox="0 0 53 53">
                                                    <g id="Group_430" data-name="Group 430" transform="translate(-1290 -1780)">
                                                        <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(1290 1780)" fill="none" stroke="#fff" stroke-width="3">
                                                            <circle cx="26.5" cy="26.5" r="26.5" stroke="none"/>
                                                            <circle cx="26.5" cy="26.5" r="25" fill="none"/>
                                                        </g>
                                                        <g id="Group_23" data-name="Group 23" transform="translate(1302.162 1819.429) rotate(-90)">
                                                            <g id="Group_13" data-name="Group 13">
                                                                <line id="Line_1" data-name="Line 1" y2="26.138" transform="translate(11.59)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                                                <path id="Path_36" data-name="Path 36" d="M0,0,11.59,11.59,23.181,0" transform="translate(0 17.086)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                                <p className=" bold largesection ml-[10px]">{button?.title}</p>
                                            </Link>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    } else if(section._type === 'chart25'){
                        return <React.Fragment key={index}>
                            <div className="chart__container bg-white items-end desktop:h-[90vh] relative">
                                <div className="left !pb-[6rem] desktop:!pb-[10rem]">
                                    <p className="large">
                                        Grüne Energie bedeutet
                                        nachhaltige Ressourcen
                                        richtig zu nutzen, die
                                        Umwelt zu schonen und
                                        gleichzeitig zu sparen.
                                    </p>
                                    <p className="large mt-base">
                                        Ob sich das lohnt? <br/>
                                        Die Zahlen sprechen
                                        für sich.
                                    </p>
                                    <Link to={section?.button?.link} className="btn smalltext bold outline orangewhite uppercase">{section?.button?.title}</Link>
                                </div>
                                <div className="right relative desktop:static pt-doublebase">
                                    <Chart25></Chart25>
                                    <div className={"absolute -top-[4rem] desktop:top-[4rem] right-1/2 translate-x-1/2 desktop:translate-x-0 desktop:right-[10vw] wide:right-[20vw] hidden tablet:flex items-center large:block large:top-[unset] large:bottom-[8rem] large:right-base xlarge:right-[10vw]"}>
                                        <div className="flex items-center mb-halfbase">
                                            <div className="w-[30px] h-[30px] bg-orange rounded-full mr-[.5rem]"></div>
                                            <p className="small legendtext">Energiekosten</p>
                                        </div>
                                        <div className="flex mb-halfbase items-center ml-base large:ml-0">
                                            <div className="w-[30px] h-[30px] bg-orange rounded-full mr-[.5rem] bgbluegrey"></div>
                                            <p className="small legendtext">Ersparnis</p>
                                        </div>
                                        <div className="flex mb-halfbase items-center ml-base large:ml-0">
                                            <div className="w-[30px] h-[30px] bg-orange rounded-full mr-[.5rem] bggreen"></div>
                                            <p className="small legendtext">Gebühren</p>
                                        </div>
                                        <div className="flex items-center mb-halfbase ml-base large:ml-0">
                                            <div className="w-[30px] h-[30px] bg-orange rounded-full mr-[.5rem] bgblack"></div>
                                            <p className="small legendtext">Einspeisung</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    } else if(section._type === 'rechner25'){
                        return <React.Fragment key={index}>
                            <Energierechner></Energierechner>
                        </React.Fragment>
                    } else if(section._type === 'cta25'){
                        return <React.Fragment key={index}>
                            <CTA25 data={section}></CTA25>
                        </React.Fragment>
                    } else if(section._type === 'seocta25'){
                        return <React.Fragment key={index}>
                            <div className="landing__info__container bgblack">
                                <div className="landing__info__wrapper">
                                    {oldpage.blogartikel.map(el => (
                                        <div className="landing__info color-white">
                                            <p className="large">{el.title}</p>
                                            <p className="small">{el.text}</p>
                                            <Link to={el.btnslug} className="btn outline white smalltext bold">{el.btntext}</Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </React.Fragment>
                    } else if(section._type === 'logomarquee25'){
                        return <React.Fragment key={index}>
                            <Marquee gradient={false} className="bgbluegrey marqueeWrapper logomarquee">
                                <div className="marqueeText">
                                    {section.images.map(el => (
                                        <span className="marqueeText__inner"><CustomSanityImage imageData={el} alt="Logo" className="imageround"></CustomSanityImage></span>
                                    ))}
                                    {section.images.map(el => (
                                        <span className="marqueeText__inner"><CustomSanityImage imageData={el} alt="Logo" className="imageround"></CustomSanityImage></span>
                                    ))}
                                </div>
                            </Marquee>
                        </React.Fragment>
                    } else if(section._type === 'team25'){
                        return <React.Fragment key={index}>
                            <div className="landing__team__container bgorange">
                                <div className=" color-white text-center">
                                    <p className="large">{section?.title}</p>
                                </div>
                                <div className="team__personas__container">
                                    {section.team.map(el => (
                                        <div className="team__persona">
                                            <GatsbyImage  image={el.image.asset.gatsbyImageData} alt={el.title} className="imageround"></GatsbyImage>
                                            <p className="small bold">{el.name}</p>
                                            <p className="small">{el.job}</p>
                                        </div>
                                    ))}
                                </div>
                                <div className="team__btns">
                                    <a href={section?.buttons[0]?.link} title="TEAM ANSEHEN" className="btn smalltext bold outline black nofill">
                                        {section?.buttons[0]?.title}
                                    </a>
                                    <Link to={section?.buttons[1]?.link} className="btn smalltext outline bold filled black hover-moveup">
                                        {section?.buttons[1]?.title}
                                    </Link>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                })}
        </Layout>
    )
}
export default Landing25

import React, {useEffect, useRef} from "react";
import "../styles/global.scss";
import {bgAnimation2, sunAnimation2} from "../animations/Animations";
import {Link} from "gatsby";
import styled from "styled-components";
import CustomSanityImage from "./helper/CustomSanityImage";
import MyPortableText from "./MyPortableText";
import MyPortableText24 from "./MyPortableText24";


const ColoredBg = styled.div`
         background-color: ${({color}) => (color ? color : "#ff603b")};
    
`;

const LandingSection25 = ({el}) => {

    let bgRef = useRef(null);
    let sunRef = useRef(null);

    useEffect(() => {
        if (bgRef.current) {
            bgAnimation2(bgRef.current);
        }
        if (sunRef.current) {
            sunAnimation2(sunRef.current);
        }
    }, []);


    return (
        <div className="section__container__wrapper">
            <div className={`section__container ${el?.textleft ? '!flex-row-reverse' : '!flex-row'}`}>
                <div className="sunsvg__container" ref={sunRef}>
                    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                         viewBox="0 0 49.69 49.69">
                        <g id="Gruppe_75" transform="translate(0.153 0.15)">
                            <g>
                                <g id="Gruppe_74" transform="translate(0 0)">
                                    <path id="Pfad_133" fill="#FFFFFF" d="M24.69-0.15C10.97-0.15-0.15,10.98-0.15,24.7c0,13.72,11.13,24.85,24.85,24.84
				c13.72,0,24.84-11.12,24.84-24.84C49.54,10.98,38.42-0.15,24.69-0.15C24.7-0.15,24.69-0.15,24.69-0.15 M24.69,11.21
				c7.45,0,13.49,6.04,13.49,13.49c0,7.45-6.04,13.49-13.49,13.48c-7.45,0-13.48-6.04-13.49-13.48
				C11.21,17.25,17.24,11.22,24.69,11.21"/>
                                </g>
                            </g>
                        </g>
                    </svg>

                </div>
                <div className="section__right">
                    <CustomSanityImage imageData={el?.image}></CustomSanityImage>
                </div>
                <div className="section__left"  >
                    <ColoredBg className={"coloredBg"} ref={bgRef} color={el?.bg?.hex}></ColoredBg>
                    <div className={"z-10"}>
                        {el?.headline1 && <h6 className="color-white uppercase text-3xl phonexl:text-4xl !leading-[.9] laptop:text-5xl large:text-6xl">{el.headline1}</h6>}
                        {el?.headline2 && <h6 className="color-black uppercase mt-base text-3xl phonexl:text-4xl !leading-[.9] laptop:text-5xl large:text-6xl">{el.headline2}</h6>}
                        {el?.items?.length > 0 && <div className={"flex tablet:block laptop:flex z-10 mt-base"}>
                            {el?.items?.map((item, index) => {
                                return <div key={index} className={"w-fit mr-base laptop:mr-doublebase mt-0 tablet:mt-base laptop:mt-0"}>
                                    <h6 className="color-black uppercase whitespace-nowrap -ml-[5px] text-3xl phonexl:text-4xl !leading-[.9] laptop:text-5xl large:text-6xl">{item?.title}</h6>
                                    <p className="small font[.7rem] laptop:font-[1rem] max-w-[140px]">
                                        {item?.text}
                                    </p>
                                </div>
                            })}
                        </div>}
                    </div>
                    <div className="flex flex-col-reverse laptop:flex-row z-10 items-end">
                        <div className="flex-1 w-full mb-halfbase mt-halfbase laptop:mt-0">
                            <Link className="uppercase block !min-w-[200px] btn sectionbtn !p-halfbase filled whiteorange bold smalltext" to={el?.button?.link}>{el.button?.title}</Link>
                        </div>
                        <div className="flex-1 w-full mt-halfbase laptop:mt-0">
                            <MyPortableText24 value={el?._rawText}></MyPortableText24>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LandingSection25

import React, {useEffect, useRef} from "react";
import "../styles/global.scss";
import {Link} from "gatsby";
import styled from "styled-components";
import BlogTag from "./BlogTag";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import MyPortableText from "./MyPortableText";
import MyPortableText24 from "./MyPortableText24";
import CustomSanityImage from "./helper/CustomSanityImage";

const Services25 = ({data}) => {
    return (
        <div className={"flex flex-col laptop:flex-row relative laptop:h-screen"}>
            {data?.services?.map((service, index) => {
                return <Link to={service?.button?.link} className={`flex-1 laptop:w-1/4 p-[3rem] transition-all duration-300 flex flex-col h-full justify-between`} style={{
                    background: `linear-gradient(to bottom, ${service?.bg?.hex}, ${service?.bg?.hex})`,
                    transition: "background 0.3s ease",
                }}
                             onMouseEnter={(e) =>
                                 (e.currentTarget.style.background = `linear-gradient(to bottom, white, ${service?.bg?.hex})`)
                             }
                             onMouseLeave={(e) =>
                                 (e.currentTarget.style.background = `linear-gradient(to bottom, ${service?.bg?.hex}, ${service?.bg?.hex})`)
                             }>
                    <div>
                        <h2 className={"text-center pp-mori-bold !font-bold text-white"}>
                            0{index+1}
                        </h2>
                        <h2 className={"text-center pp-mori text-4xl laptop:text-2xl desktop:text-3xl large:text-4xl"}>
                            {service?.title}
                        </h2>
                        <div className={"mt-base"}>
                            <CustomSanityImage objectFit={"contain"} alt={service?.title} imageData={service?.image}></CustomSanityImage>
                        </div>
                    </div>
                    <div>
                        <div className="mb-base laptop:pb-[5rem] mt-base laptop:mt-0">
                            <MyPortableText24 value={service?._rawText}></MyPortableText24>
                        </div>
                        <div className="!w-full btn outline landingbtn smalltext bold white uppercase">{service?.button?.title}</div>
                    </div>
                </Link>
            })}
            <Link to={data?.button?.link} className="!hidden laptop:!block absolute bottom-[8rem] left-[3rem] !w-[calc(100%-6rem)] btn filled landingbtn bold whiteorange uppercase">
                <div className="pt-[1px]">
                    {data?.button?.title}
                </div>
            </Link>
        </div>
    )
}
export default Services25

import React, {useEffect, useRef} from "react";
import "../styles/global.scss";
import * as d3 from "d3";
import {chartAnimation, chartPercentageAnimation, slideIn} from "../animations/Animations";
import {GatsbyImage} from "gatsby-plugin-image";
import {Link} from "gatsby";

const CTA25 = ({data}) => {

    let iconRef1 = useRef();

    useEffect(() => {

        const icons = [
            iconRef1.current,
        ];
        slideIn(icons);
    });

    return (
        <div className="eniosoltext__container !min-h-[unset] !py-quadbase" style={{background: data?.bg?.hex}}>
            <h5 className={"!max-w-[700px]"}>
                {data?.text1}
                <div className="slidein__container"><div className="slidein" ref={iconRef1}><GatsbyImage loading={'eager'} className="introtext__image" image={data?.image.asset.gatsbyImageData} alt="Grüne Energie"></GatsbyImage></div></div>
                {data?.text2}
            </h5>
        </div>
    )
}
export default CTA25

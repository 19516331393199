import React, {useEffect, useRef, useState} from "react";
import "../styles/global.scss";
import {Link} from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {Autoplay, Navigation} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";

import {useBreakpoint} from "gatsby-plugin-breakpoints";


const TestimonialSlide25 = ({el}) => {

    const [showImage, setShowImage] = useState(false);

    const breakpoint = useBreakpoint();

    return (
                                <div className="service__testimonial__container color-blackonwhite" onMouseEnter={() => setShowImage(true)}
                                     onMouseLeave={() => setShowImage(false)}>
                                    <div className="service__testi__left">
                                        <GatsbyImage className="testimonialimg" image={el.image.asset.gatsbyImageData} alt={el.title} style={{ opacity:breakpoint.sm ? 1 : (showImage ? 1 : 0)}}></GatsbyImage>
                                        <p className="large">{el.text}</p>
                                        <p className="small subtext">{el.title}</p>
                                        <p className="small">{el.ort}</p>
                                    </div>
                                </div>
    )
}
export default TestimonialSlide25

import React, {useEffect, useRef} from "react";
import "../styles/global.scss";
import * as d3 from "d3";
import {chartAnimation, chartPercentageAnimation, slideIn} from "../animations/Animations";
import {GatsbyImage} from "gatsby-plugin-image";
import {Link} from "gatsby";

const HeroText = ({images}) => {

    let iconRef1 = useRef();
    let iconRef2 = useRef();
    let iconRef3 = useRef();
    let iconRef4 = useRef();

    useEffect(() => {

        const icons = [
            iconRef1.current,
            iconRef2.current,
            iconRef3.current,
            iconRef4.current
        ];
        slideIn(icons);
    });

    return (
        <div className="eniosoltext__container" id="eniosol">
            <h5>
                eniosol ist Ihr Anbieter für maßgefertigte
                <div className="slidein__container"><div className="slidein" ref={iconRef1}><GatsbyImage loading={'eager'} className="introtext__image" image={images[0].asset.gatsbyImageData} alt="Grüne Energie"></GatsbyImage></div></div>
                Energielösungen direkt aus der
                <div className="slidein__container">
                    <div className="slidein" ref={iconRef2}><GatsbyImage loading={'eager'} className="introtext__image"  image={images[1].asset.gatsbyImageData} alt="Bessere Zukunft"></GatsbyImage></div></div>
                Region. <br/>
                Senken Sie Ihre Energiekosten durch
                <div className="slidein__container">
                    <div className="slidein" ref={iconRef3}><GatsbyImage loading={'eager'} className="introtext__image"  image={images[2].asset.gatsbyImageData} alt="Klimafreundlich"></GatsbyImage></div></div>
                Photovoltaik, Batteriespeicher, Wärme
                <div className="slidein__container">
                    <div className="slidein" ref={iconRef4}><GatsbyImage loading={'eager'} className="introtext__image" image={images[3].asset.gatsbyImageData} alt="Strompreise"></GatsbyImage></div></div>
                und E-Mobilität.
            </h5>
            <Link to={'/kontakt'} className="btn outline landingbtn smalltext bold orangewhite uppercase">Jetzt Kontaktieren</Link>
        </div>
    )
}
export default HeroText

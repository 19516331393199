import React from "react";
import {graphql} from "gatsby";
import 'swiper/css';
import "swiper/css/navigation";
import "../styles/global.scss";

import Landing25 from "../components/pageComponent/Landing25";

export const query = graphql`
    {
        allSanityHome2025{
            edges{
                node{
                    pageBuilder {
                        ... on SanityHero25 {
                            _type
                            image {
                                alt
                                asset {
                                    gatsbyImageData
                                }
                            }
                            button1 {
                                title
                                link
                            }
                            button2 {
                                title
                                link
                            }
                            headline
                        }
                        ... on SanityIntro25 {
                            _type
                            images {
                                alt
                                asset {
                                    gatsbyImageData
                                    _type
                                }
                            }
                        }
                        ... on SanityFullimage25 {
                            _type
                            image {
                                alt
                                asset {
                                    gatsbyImageData
                                    _type
                                }
                            }
                        } ... on SanityDienstleistungen25 {
                            _type
                            headline1
                            headline2
                            _rawText1
                            _rawText2
                            buttons{
                                title
                                link
                                color{
                                    hex
                                }
                            }
                            items{
                                title
                                text
                            }
                        }
                        ... on SanityServices25 {
                            _key
                            _type
                            button {
                                title
                                link
                            }
                            services {
                                title
                                _rawText
                                image {
                                    asset {
                                        gatsbyImageData
                                    }
                                }
                                button {
                                    title
                                    link
                                }
                                bg {
                                    hex
                                }
                            }
                        }
                        ... on SanityMarquee25 {
                            _type
                            headline
                            color{
                                hex
                            }
                        }
                        ... on SanityTestimonials25 {
                            _type
                        }
                        ... on SanitySeocta25 {
                            _type
                        }
                        ... on SanityCta25 {
                            _type
                            bg {
                                hex
                            }
                            image {
                                alt
                                asset {
                                    gatsbyImageData
                                }
                            }
                            text1
                            text2
                        }
                        ... on SanityTeam25 {
                            _type
                            title
                            buttons{
                                title
                                link
                            }
                            team{
                                job
                                name
                                image{
                                    asset{
                                        gatsbyImageData
                                    }
                                }
                            }
                        }
                        ... on SanityRechner25 {
                            _type
                        }
                        ... on SanityLogomarquee25 {
                            _type
                            images{
                                asset{
                                    gatsbyImageData
                                }
                            }
                        }
                        ... on SanityChart25 {
                            _type
                            _rawText
                            button {
                                title
                                link
                            }
                        }
                        ... on SanityInfo25 {
                            _type
                            _rawText
                            headline1
                            headline2
                            image{
                                asset{
                                    gatsbyImageData
                                }
                            }
                            button {
                                title
                                link
                            }
                            items{
                                title
                                text
                            }
                            bg {
                                hex
                            }
                            textleft
                        }
                    }
                    seo{
                        seo_title
                        seo_description
                        seo_image{
                            asset{
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
        }
        allSanityStartseite {
            edges {
                node {
                    title
                    video
                    section {
                        title
                        text
                        marqueetext
                        links
                        image {
                            asset {
                                gatsbyImageData(width: 1920)
                            }
                        }
                        color
                        btntext
                        btnslug
                    }
                    image {
                        asset {
                            gatsbyImageData
                        }
                    }
                    logos{
                        asset{
                            gatsbyImageData
                        }
                    }
                    images{
                        asset{
                            gatsbyImageData
                        }
                    }
                    sectionlarge{
                        sectiontext1
                        sectiontext2
                        sectionimage{
                            asset{ gatsbyImageData }
                        }
                        video
                    }

                    energierechnermarquee
                    ansprechpartner {
                        image {
                            asset {
                                gatsbyImageData
                            }
                        }
                        jobbezeichnung
                        title
                    }
                    blogartikel {
                        text
                        btnslug
                        btntext
                        title
                    }

                    testimonialmarquee
                    testimonials{
                        title
                        ort
                        text
                        image{ asset { gatsbyImageData}}
                    }
                }
            }
        }
        allSanitySettings {
            edges {
                node {
                    instalink
                    fblink
                    linkedinlink
                }
            }
        }
    }
`


const IndexPage = ({data}) => {

    const page = data.allSanityStartseite.edges[0].node;
    const page3 = data.allSanityHome2025.edges[0].node;

    return <Landing25 oldpage={page} page={page3} settings={data?.allSanitySettings?.edges[0].node}/>
}

export default IndexPage;
